import React from "react"
import { graphql } from "gatsby"
import ProductPage from '../../components/gallery/productPage'
import Seo from "../../components/SEO"


const Brown = ({data}) => {
    
    const _title = "Larix Medium Brown Lamp";


    const _quotes = [
        `Embracing tree, wisdom of roots.`,
        `A mystic lamp with flames burning up towards the pyramidal dome. Lighting is warm and
        its rays leaving the thready fabric evoke a pleasant and familiar emotion. The sense of
        earthiness, trees and roots is bound by the shades of brown which alude to something firm, 
        profound and everlasting. The golden flames give way to the stiffness and add a mild 
        thrill to the lamp as a whole.`,
        `Immersed in this atmosphere, one might dream away many an evening or simply 
        enjoy the company of those closest to their heart.`
    ]


    const _properties = {
      "Height": "83 cm",
      "Width": "22 cm",
      "Weight": "1.5 kg"
    }


    const _walloftext = {
        "Product's purpose": [
            `
                The lamp is meant primarily for ambient lighting. It decorates the space with 
                mild lighting and rich design. The Larix Medium Brown Lamp is solid and strong, 
                which it reflects on the surroundings. THe warm colors give the room a feeling 
                of warmth and homeliness.
            `
         ],
        "Materials": [
            `
                The lamp is made entirely of natural materials. 
                The wooden parts are made of solid beech wood, coated with 
                water-soluble acrylics with natural pigments and decorated 
                with oil paints and a pure gold coating.
                The shade is made of high quality silk, embroidered with 
                colorful decorative shapes.
            `
        ],
        "Production": [
            `
                Wooden parts are machine painted and patinated. The painter then hand-paints
                decorative elements with oil paints. The production of the lamp is completed by 
                the restorer by hand application of gilding paste - a paste with fine particles 
                of pure gold. 
            `,
            `
                The base for the shade is high quality silk, to which decorative patterns
                and shapes are machine-embroidered. The master of embroidery has developed the 
                technology to such an extent that needle stitches imitate hand embroidery, 
                which is also evident when looking closely at the embroidery.
            `,
            `
                Lastly, the master stretches the silk over the metal frame 
                and sews upholstery strips to the edges.
            `
        ],
    }


    return (
        <>
          <Seo title={_title} />
          <ProductPage 
            galleryItems={data.allFile}
            title={_title}
            quotes={_quotes}
            properties={_properties}
            walloftext={_walloftext}
            spinnerSprite={{on: data.brown_on, off: data.brown_off}}
          />
        </>
    );
}


export default Brown


export const query = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "brown"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                original {
                    src
                    height
                    width
                }
                webp: resize(width: 334, toFormat: WEBP, pngQuality: 50) {
                    src
                    height
                    width
                }
                png: resize(width: 334, toFormat: PNG, pngQuality: 50) {
                    src
                }
                thumbnail: resize(height: 150, toFormat: WEBP, webpQuality: 80) {
                    src
                    height
                    width
                }
            }
            name
        }
    }
    brown_on: allFile(filter: {relativeDirectory: {eq: "spin/brown_on"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
    brown_off: allFile(filter: {relativeDirectory: {eq: "spin/brown_off"}} sort: {fields: name}) {
        nodes {
            childImageSharp {
                webp: resize(height: 700, toFormat: WEBP, webpQuality: 90) {
                    src
                    width
                    height
                }
            }
        }
    }
}`

